'use client'

import { useState, useRef, useEffect } from 'react'

import { HeaderDesktopNavigationPopup } from './header-desktop-navigation-popup'
import { HeaderNavItem } from '@/components/header/components/header-navigation/navigation-types'
import CategoryText from '@/components/header/components/header-navigation/desktop/header-desktop-navigation-text'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'
import { getIsExternal, getSkeletonTarget } from '@/common/utils'
import { useStoreContext } from '@/providers'
import { useSkeletonContext } from '@/providers/skeleton/skeleton-context'

interface HeaderDesktopNavigationProps {
  items?: HeaderNavItem[]
}

export const HeaderDesktopNavigation = ({
  items,
}: HeaderDesktopNavigationProps) => {
  const [hoveredItemHref, setHoveredItemHref] = useState<string | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const { storeCode } = useStoreContext()
  const { isVisible } = useSkeletonContext()

  const handleMouseEnter = (href: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setHoveredItemHref(href)
    }, 100)
  }

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setHoveredItemHref(null)
    }, 100)
  }

  useEffect(() => {
    if (isVisible) {
      setHoveredItemHref(null)
    }
  }, [isVisible])

  return (
    <ul
      data-test={selectors.common.header.menu}
      id="desktop-nav"
      className="relative hidden md:flex md:flex-wrap w-full mt-3"
    >
      {items?.map((item: HeaderNavItem, index) => (
        <li
          key={item.href}
          className="group"
          onMouseEnter={() => handleMouseEnter(item.href)}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            className={`no-underline hover:no-underline leading-[53px] h-full py-4 px-[15px] ${
              index === 0 && 'pl-0'
            }`}
            href={item.href}
            isExternal={getIsExternal(storeCode, item.href)}
            skeletonLoader={{
              target: getSkeletonTarget(storeCode, item.href),
            }}
          >
            <CategoryText item={item} />
          </Link>

          <HeaderDesktopNavigationPopup
            isVisible={hoveredItemHref === item.href}
            items={item.items}
            isActive={item.active}
          />
        </li>
      ))}
    </ul>
  )
}
