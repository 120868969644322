'use client'

import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { Flex } from '@/components/flex'
import { IconButton } from '@/components/icon-button'
import { SearchIcon, UserIcon } from '@/components/icons/svg'
import { Popup } from '@/components/popup'
import { Text } from '@/components/text'
import { useAuthContext } from '@/providers'
import {
  HeaderAccountPopupMenu,
  HeaderCartMenu,
  HeaderCartButton,
} from './controls'
import { CustomerPopupMenuItems, GuestPopupMenuItems } from './controls'
import { useHeaderContext } from './header-context'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'

export interface HeaderControlsDesktopProps {
  className?: string
}

export const DesktopHeaderControls = ({
  className,
}: HeaderControlsDesktopProps) => {
  const t = useTranslations('Header')
  const { customerData, isLoggedIn } = useAuthContext()
  const { state, actions } = useHeaderContext()

  const customerName = [customerData?.firstname, customerData?.lastname]
    .filter((text) => !!text)
    .join(' ')

  return (
    <Flex className={className}>
      <div className="flex items-center gap-x-3">
        <IconButton
          onClick={() => actions.setIsMobileSearch(!state.isMobileSearch)}
          variant="ghost"
          className="md:hidden"
          size="sm"
          aria-label={t('search.placeholder')}
        >
          <SearchIcon className="w-6 h-6 md:h-7 md:w-7" />
        </IconButton>
        <Popup
          openOnHover
          content={
            <HeaderAccountPopupMenu
              items={
                customerName ? (
                  <CustomerPopupMenuItems />
                ) : (
                  <GuestPopupMenuItems />
                )
              }
            />
          }
        >
          {({ onClose }) => (
            <div className="hidden md:flex">
              <Link
                isExternal
                data-test={selectors.common.header.login}
                className="cursor-pointer text-black h-full hover:bg-transparent flex justify-center items-center gap-x-2"
                href={
                  isLoggedIn ? '/customer/account' : '/customer/account/login'
                }
                aria-label={t('cart')}
                onClick={onClose}
              >
                {isLoggedIn && (
                  <Text
                    size="sm"
                    align="right"
                    color="active"
                    className="self-center hidden lg:block w-[140px]"
                  >
                    {customerName}
                  </Text>
                )}
                <UserIcon
                  className={twMerge(
                    `h-6 w-6`,
                    isLoggedIn ? 'fill-current text-secondary' : '',
                  )}
                />
              </Link>
            </div>
          )}
        </Popup>
        <>
          <Popup
            className="hidden md:block"
            openOnHover
            content={<HeaderCartMenu />}
          >
            {() => <HeaderCartButton href="/checkout/cart" />}
          </Popup>
          <div className="md:hidden">
            <HeaderCartButton href="/checkout/cart" />
          </div>
        </>
      </div>
    </Flex>
  )
}
