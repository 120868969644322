import React, { PropsWithChildren, ReactElement } from 'react'

import { WithClassName } from '@/common/types/utility'
import { usePreventScroll } from '@/common/hooks/use-prevent-scroll'
import { Overlay } from './overlay'
import { ModalContent } from './modal-content'
import { ModalBody, ModalBodyProps } from './modal-body'
import { usePressEscape } from './hooks/use-press-escape'
import { useIsModalVisible } from './hooks/use-is-modal-visible'
import { XIcon } from '../icons/svg/x'

type ModalProps = {
  delay?: number
  overlay?: ReactElement
  withAnimation?: boolean
  closeButton?: boolean
} & Omit<ModalBodyProps, 'children'>

export const Modal = ({
  closeButton = false,
  delay,
  isOpen,
  onClose,
  overlay,
  children,
  className,
  withAnimation,
  ...props
}: PropsWithChildren<WithClassName<ModalProps>>) => {
  const isVisible = useIsModalVisible({ isOpen, delay })

  usePreventScroll(isVisible)
  usePressEscape({ onPress: onClose, isOpen })

  if (!isVisible) {
    return null
  }

  return (
    <ModalBody isOpen={isOpen} onClose={onClose} {...props}>
      {overlay ?? <Overlay />}

      <ModalContent className={className} withAnimation={withAnimation}>
        {closeButton && (
          <button className="absolute top-5 right-5" onClick={onClose}>
            <XIcon />
          </button>
        )}
        {children}
      </ModalContent>
    </ModalBody>
  )
}
