'use client'

/* eslint-disable camelcase */
import { Image } from '@/common/components/image/image'
import { StoreCodeType } from '@/common/types'
import { Flex } from '@/components/flex'
import { Link } from '@/components/link/link'
import { selectors } from '@/common/constants/selectors-constants'
import type { StaticImageData } from 'next/image'
import GB_Logo_empty from '@/assets/logo/GB_Logo_empty.png'
import GB_Logo_Energy_BA from '@/assets/logo/GB_Logo_Energy_BA.png'
import GB_Logo_Energy_BG from '@/assets/logo/GB_Logo_Energy_BG.png'
import GB_Logo_Energy_COM from '@/assets/logo/GB_Logo_Energy_COM.png'
import GB_Logo_Energy_CZ from '@/assets/logo/GB_Logo_Energy_CZ.png'
import GB_Logo_Energy_DE from '@/assets/logo/GB_Logo_Energy_DE.png'
import GB_Logo_Energy_GR from '@/assets/logo/GB_Logo_Energy_GR.png'
import GB_Logo_Energy_HR from '@/assets/logo/GB_Logo_Energy_HR.png'
import GB_Logo_Energy_HU from '@/assets/logo/GB_Logo_Energy_HU.png'
import GB_Logo_Energy_PL from '@/assets/logo/GB_Logo_Energy_PL.png'
import GB_Logo_Energy_RO from '@/assets/logo/GB_Logo_Energy_RO.png'
import GB_Logo_Energy_RS from '@/assets/logo/GB_Logo_Energy_RS.png'
import GB_Logo_Energy_SI from '@/assets/logo/GB_Logo_Energy_SI.png'
import GB_Logo_Energy_SK from '@/assets/logo/GB_Logo_Energy_SK.png'
import GB_Logo_Energy_IT from '@/assets/logo/GB_Logo_Energy_IT.png'
import GB_Logo_Energy_RU from '@/assets/logo/GB_Logo_Energy_RU.png'
import GB_Logo_Energy_UA from '@/assets/logo/GB_Logo_Energy_UA.png'

const LOGOS: Record<StoreCodeType, StaticImageData> = {
  gymbeamba: GB_Logo_Energy_BA,
  gymbeambg: GB_Logo_Energy_BG,
  gymbeamcom: GB_Logo_Energy_COM,
  gymbeamcz: GB_Logo_Energy_CZ,
  gymbeamde: GB_Logo_Energy_DE,
  gymbeamat: GB_Logo_Energy_DE, // TODO: replace with AT logo
  gymbeamgr: GB_Logo_Energy_GR,
  gymbeamhr: GB_Logo_Energy_HR,
  gymbeamhu: GB_Logo_Energy_HU,
  gymbeampl: GB_Logo_Energy_PL,
  gymbeamro: GB_Logo_Energy_RO,
  gymbeamrs: GB_Logo_Energy_RS,
  gymbeamsi: GB_Logo_Energy_SI,
  gymbeamsk: GB_Logo_Energy_SK,
  gymbeamit: GB_Logo_Energy_IT,
  ru: GB_Logo_Energy_RU,
  ua: GB_Logo_Energy_UA,
}

export function HeaderLogo({
  storeCode,
  originUrl,
}: {
  storeCode: StoreCodeType
  originUrl: string
}) {
  const img = LOGOS[storeCode] ?? GB_Logo_empty

  return (
    <Flex
      className="h-[40px] md:h-[75px] lg:h-[70px] justify-center md:justify-start"
      data-test={selectors.common.header.logo}
    >
      <Link
        href={originUrl}
        className="h-full"
        title="GymBeam s.r.o."
        aria-label="store logo"
        skeletonLoader={{
          target: 'homepage',
        }}
      >
        <Image
          src={img}
          width={265}
          height={75}
          className="h-full w-auto max-w-none"
          alt="Logo"
        />
      </Link>
    </Flex>
  )
}
