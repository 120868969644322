import * as React from 'react'

import type { SVGProps } from 'react'

const SvgAt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 600"
    width="1em"
    height="1em"
    {...props}
  >
    <rect fill="#c8102e" width="900" height="600" />
    <rect fill="#fff" y="200" width="900" height="200" />
  </svg>
)
export default SvgAt
