'use client'

import React from 'react'
import { useTranslations } from 'next-intl'

import { CartItemFragment } from '@/api'
import { Alert } from '@/components/alert'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'
import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'
import { CartPopupMenuItem } from './cart-popup-menu-item'

interface CartItemsProps {
  cartItems: CartItemFragment[]
}

export const CartPopupMenuItems = ({ cartItems }: CartItemsProps) => {
  const t = useTranslations('Header')

  const isLg = useBrowserMediaQuery({
    breakpoint: 'lg',
  })
  const dynamicHeight = isLg ? Math.floor(window.innerHeight * 0.75) : 320

  const hasProductLink = (cartItem: CartItemFragment) => {
    return (
      cartItem?.product?.canonical_url &&
      cartItem?.product?.canonical_url !== 'false'
    )
  }

  return (
    <ol
      className="overflow-y-auto -mr-5 pr-5"
      style={{ maxHeight: `${dynamicHeight}px` }}
    >
      {cartItems.reverse().map((cartItem: CartItemFragment) => {
        return (
          <li
            data-test={selectors.common.card.cardItem}
            className="pb-5 group"
            key={cartItem?.uid}
          >
            {hasProductLink(cartItem) ? (
              <Link
                className="flex"
                href={cartItem?.product?.canonical_url || '#'}
              >
                <CartPopupMenuItem cartItem={cartItem} />
              </Link>
            ) : (
              <CartPopupMenuItem cartItem={cartItem} />
            )}

            {cartItem?.prices?.price_including_tax?.value === 0 && (
              <Alert severity="warning" className="mt-1">
                {t('gift')}
              </Alert>
            )}
          </li>
        )
      })}
    </ol>
  )
}
