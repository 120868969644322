import React from 'react'

import { HeaderDesktopNavigationItem } from './header-desktop-navigation-item'
import { HeaderNavItem } from '../navigation-types'
import { cn } from '@/common/utils/lib'

interface HeaderDesktopNavigationPopupProps {
  items?: HeaderNavItem[]
  isActive?: boolean
  isVisible?: boolean
}

export const HeaderDesktopNavigationPopup = ({
  items,
  isActive,
  isVisible,
}: HeaderDesktopNavigationPopupProps) => {
  return items?.length ? (
    <ul
      className={cn(
        'absolute left-0 z-10',
        'w-full p-[12px] bg-white',
        'border-black border-[2px]',
        isActive && 'mt-[3px]',
        isVisible ? 'block' : 'hidden',
      )}
    >
      {items.map((item: HeaderNavItem) => (
        <HeaderDesktopNavigationItem key={item.href} item={item} />
      ))}
    </ul>
  ) : null
}
