import { useTranslations } from 'next-intl'

import { CartItemFragment } from '@/api'
import { Image } from '@/common/components/image/image'
import { Currency } from '@/components/currency'
import { Text } from '@/components/text'
import { useStoreContext } from '@/providers'

type CartPopupMenuItemProps = {
  cartItem: CartItemFragment
}

export const CartPopupMenuItem = ({ cartItem }: CartPopupMenuItemProps) => {
  const t = useTranslations('Header')

  const {
    storeConfig: { currency },
  } = useStoreContext()

  /**
   * @description This function exists because if product is configurable, and the images does not change
   * with configuration, for example size with T-shirt, image is stored in different object attribute
   * there might be a case where product image is not available
   */
  const getImageUrl = (
    cartItem: CartItemFragment & { __typename: 'ConfigurableCartItem' },
  ): string => {
    const isConfiguredVariantImageInvalid =
      cartItem.configured_variant?.image?.url?.small?.includes('coming-soon') ??
      false

    if (
      isConfiguredVariantImageInvalid &&
      cartItem.product?.image?.url?.small
    ) {
      return cartItem.product.image.url.small
    }

    return cartItem.configured_variant?.image?.url?.small ?? ''
  }

  const hasSingleImage = (cartItem: CartItemFragment) => {
    return (
      cartItem.__typename === 'GiftCardCartItem' ||
      cartItem.__typename === 'SimpleCartItem' ||
      cartItem.__typename === 'VirtualCartItem'
    )
  }

  return (
    <>
      {cartItem.__typename === 'ConfigurableCartItem' &&
        cartItem?.configured_variant?.image?.url?.small && (
          <Image
            src={getImageUrl(cartItem)}
            alt={cartItem?.configured_variant?.image?.label || ''}
            priority
            width={67}
            height={67}
            className="my-auto mr-4"
          />
        )}
      {hasSingleImage(cartItem) && cartItem?.product.image?.url?.small && (
        <Image
          src={cartItem?.product.image?.url?.small}
          alt={cartItem?.product.image?.label || ''}
          priority
          width={67}
          height={67}
          className="my-auto mr-4"
        />
      )}
      <div>
        <Text className="block group-hover:underline" size="sm" weight="bold">
          {cartItem?.product?.name}
        </Text>
        {'configurable_options' in cartItem &&
          cartItem.configurable_options?.map((option) => {
            if (option?.value_label === 'single_variant') {
              return null
            }

            return (
              <Text className="block" size="sm" key={option?.id}>
                {option?.value_label}
              </Text>
            )
          })}
        <Text weight="bold" color="red" size="sm" className="block">
          <Currency
            className="block"
            currency={currency}
            price={cartItem.prices?.price_including_tax?.value}
          />
        </Text>
        <Text className="block" size="sm">
          {t('qty')}: {cartItem.quantity}
        </Text>
      </div>
    </>
  )
}
