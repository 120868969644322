import React, { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface HeaderAccountPopupMenuProps {
  items: ReactNode
  className?: string
}

export const HeaderAccountPopupMenu: FC<HeaderAccountPopupMenuProps> = ({
  items,
  className,
}) => {
  return (
    <div className={twMerge('flex flex-col justify-start w-full', className)}>
      {items}
    </div>
  )
}
