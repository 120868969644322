'use client'

import React from 'react'
import { useTranslations } from 'next-intl'

import { IconButton } from '@/components/icon-button'
import { CloseIcon, UserIcon } from '@/components/icons/svg'
import { Text } from '@/components/text'
import { useAuthContext } from '@/providers'
import { HeaderMobileControlsProps } from './header-types'
import { LOGOUT_PATH, MY_ACCOUNT_PATH } from '../paths'
import { Link } from '@/components/link/link'

export const MobileHeaderControls = ({
  onClose,
}: HeaderMobileControlsProps) => {
  const t = useTranslations('Header')
  const { customerData } = useAuthContext()

  const customerName = [customerData?.firstname, customerData?.lastname]
    .filter((text) => !!text)
    .join(' ')

  const isLoggedIn = !!customerName

  // TODO: check staging if onClick is needed for Link LOGOUT_PATH

  return (
    <div className="flex flex-1 justify-between">
      <Link className="flex" href={MY_ACCOUNT_PATH} isExternal>
        <UserIcon className="ml-3 w-6 h-6 my-auto" />
        {isLoggedIn && (
          <Text align="center" color="active" className="pl-4 self-center">
            {customerName}
          </Text>
        )}
      </Link>
      <div className="flex">
        {isLoggedIn && (
          <Link href={LOGOUT_PATH} className="self-center underline" isExternal>
            {t('logOut')}
          </Link>
        )}
        <IconButton className="ml-2" onClick={onClose} variant="ghost">
          <CloseIcon className="w-6 h-6" />
        </IconButton>
      </div>
    </div>
  )
}
