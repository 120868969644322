'use client'
import React from 'react'

import { Text } from '@/components/text'
import { HeaderNavItem } from '../navigation-types'

interface CategoryTextProps {
  item: HeaderNavItem
}

export default function CategoryText({ item }: CategoryTextProps) {
  return (
    <Text
      className="lg:text-lg"
      case="upper"
      weight="bold"
      color={item.isOpen ? 'red' : 'black'}
    >
      {item.label}
    </Text>
  )
}
